.header {
    padding: 2.8vw 9.375vw;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 5;
    transition: all ease 0.4s;

    &.off-hero {
        background: #181818;
    }

    .header-logo {
        width: 12.5vw;
        cursor: pointer;
    }

    .header-nav {
        display: flex;
        gap: 2.8vw;
        align-items: center;

        a {
            text-transform: uppercase;
            color: #fff;
            text-decoration: none;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            gap: 0.2vw;
            font-size: 0.97vw;

            &::after {
                content: "";
                height: 0.08vw;
                max-width: 0;
                background: #fff;
                transition: all ease 0.4s;
            }

            &:hover {
                &::after {
                    max-width: 100%;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .header {
        padding: 4.2vw 14.0625vw;

        .header-logo {
            width: 18.75vw;
        }

        .header-nav {
            gap: 4.2vw;

            a {
                gap: 0.3vw;
                font-size: 1.455vw;

                &::after {
                    height: 0.12vw;
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .header {
        padding: 8.4vw 8.125vw;

        .header-logo {
            width: 37.5vw;
        }

        .header-nav {
            gap: 8.4vw;

            a {
                gap: 0.6vw;
                font-size: 2.91vw;

                &::after {
                    height: 0.24vw;
                }
            }
        }
    }
}
