.about-us {
    .about-us-info-content-container {
        display: grid;
        grid-template-columns: 0.6fr 0.4fr;
        gap: 1.67vw;

        .about-us-info-content-item {
            z-index: 1;
            .title {
                text-align: left;
            }

            .subtitle {
                text-transform: none;
                text-align: left;
            }

            img {
                width: 100%;
            }

            &.small-img {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 16vw;
                    transform: rotate(-7deg);
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .about-us {
        .about-us-info-content-container {
            gap: 2.505vw;

            .about-us-info-content-item {

                &:first-child{
                    grid-column-start: 1;
                    grid-column-end: 3;
                    
                }

                &:nth-child(3){
                    grid-column-start: 1;
                    grid-column-end: 3;
                }

                &.small-img {
                    grid-column-start: 2;
                    grid-column-end: 3;
                    grid-row-start: 2;
                    grid-row-end: 3;

                    img {
                        width: 24vw;
                    }
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .about-us {
        .about-us-info-content-container {
            gap: 5.01vw;
            grid-template-columns: auto;

            .about-us-info-content-item{
                .title{
                    text-align: center;
                }

                .subtitle{
                    text-align: center;
                }
            }

            .about-us-info-content-item {
                &.small-img {
                    img {
                        width: 24vw;
                    }
                }
            }
        }
    }
}
