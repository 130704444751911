.email-form {
    width: 100%;
    background-image: url('../../../assets/home/emailFormBg.jpg');
    z-index: 3;

    .email-form-wrapper {
        background-color: rgba(0, 0, 0, 0.9);

        .email-form-container {
            padding: 2.5vw 0;
            display: flex;
            gap: 4.7vw;
            align-items: center;

            img {
                width: 25.38vw;
            }

            .email-form-content {
                display: flex;
                flex-direction: column;
                width: 100%;

                .title {
                    font-size: 2.7vw;
                    text-align: left;
                }

                .subtitle {
                    font-size: 1.11vw;
                    text-align: left;
                    color: #fff;
                    font-weight: normal;
                    text-transform: none;
                }

                .email-form-input-container {
                    width: 100%;
                    margin-top: 2.5vw;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    gap: 1.25vw;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .email-form {
        .email-form-wrapper {
            .email-form-container {
                padding: 7.5vw 0;
                gap: 7.05vw;
                display: flex;
                flex-direction: column;

                img {
                    width: 38.07vw;
                }

                .email-form-content {
                    .title {
                        font-size: 4.05vw;
                    }

                    .subtitle {
                        font-size: 1.665vw;
                    }

                    .email-form-input-container {
                        margin-top: 3.75vw;
                        gap: 1.875vw;
                    }
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .email-form {
        .email-form-wrapper {
            .email-form-container {
                padding: 15vw 0;
                gap: 14.1vw;

                img {
                    width: 76.14vw;
                }

                .email-form-content {
                    .title {
                        font-size: 8.1vw;
                    }

                    .subtitle {
                        font-size: 3.33vw;
                    }

                    .email-form-input-container {
                        margin-top: 7.5vw;
                        gap: 3.75vw;
                    }
                }
            }
        }
    }
}
