body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *::before, *::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Plus Jakarta Sans", serif;
}

@font-face{
  font-family: 'Ganora';
  src: url('./assets/general/Ganora.otf') format('opentype');
}

.container {
  width: 81.25vw;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 90vw;
  }

  @media (max-width: 430px) {
    width: 95vw;
  }
}

.title {
  color: #fff;
  font-size: 4.17vw;
  line-height: 135%;
  font-family: 'Ganora';
  font-weight: 400;
  text-align: center;
}

.subtitle {
  color: #888;
  font-size: 0.97vw;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.15vw;
  text-align: center;
}



@media (max-width: 768px) {
  .title {
    font-size: 6vw;
  }
  
  .subtitle{
    font-size: 1.5vw;
  }
}

@media (max-width: 430px) {
  .title {
    font-size: 12vw;
  }

  .subtitle{
    font-size: 3vw;
  }
}

#tsparticles{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}