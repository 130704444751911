.home-about-us {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;

    .home-about-us-section {
        display: flex;
        flex-direction: column;
        position: relative;

        .home-about-us-title-container {
            display: flex;
            flex-direction: column;

            .title {
                width: 52.7vw;
                text-align: left;
            }

            .subtitle {
                text-align: left;
            }
        }

        .home-about-us-image {
            width: 52.9vw;
            position: relative;
            top: -3vw;
        }

        .home-about-us-data-container {
            padding: 3.82vw;
            background: #333;
            display: flex;
            align-items: center;
            gap: 6.25vw;
            position: absolute;
            bottom: 0;
            right: -12.15vw;

            .home-about-us-data-item {
                h2 {
                    color: #fff;
                    font-family: 'Ganora';
                    font-weight: 400;
                    font-size: 3.5vw;
                    p{
                        color: #fff;
                        font-family: 'Ganora';
                        font-weight: 400;
                        font-size: 3.5vw;
                    }
                }

                p {
                    color: #888;
                    font-size: 1.2vw;
                }
            }
        }

        .home-about-us-content {
            font-size: 1.11vw;
            color: #AAA;
            line-height: 140%;
            margin-bottom: 1.75vw;
        }

        .button {
            width: 14.65vw;
        }
    }
}

@media (max-width: 768px) {
    .home-about-us {
        flex-direction: column;
        gap: 5.4vw;

        .home-about-us-section {
            .home-about-us-title-container {
                .title {
                    width: 79.05vw;
                }
            }

            .home-about-us-image {
                width: 79.35vw;
                top: -4.5vw;
            }

            .home-about-us-data-container {
                padding: 5.73vw;
                gap: 9.375vw;
                right: -5.225vw;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .home-about-us-data-item {
                    h2 {
                        font-size: 5.25vw;
                        p{
                            font-size: 1.8vw;
                        }
                    }

                    p {
                        font-size: 1.8vw;
                    }
                }
            }

            .home-about-us-content {
                font-size: 1.665vw;
                margin-bottom: 2.625vw;
            }

            .button {
                width: 21.975vw;
            }
        }
    }
}

@media (max-width: 430px) {
    .home-about-us {
        flex-direction: column;
        gap: 55.8vw;

        .home-about-us-section {
            .home-about-us-title-container {
                .title {
                    width: 95vw;
                }
            }

            .home-about-us-image {
                width: 95vw;
                top: -13.5vw;
            }

            .home-about-us-data-container {
                padding: 10vw;
                gap: 10vw;
                bottom: -45vw;
                right: 4vw;

                .home-about-us-data-item {
                    h2 {
                        font-size: 10.5vw;

                        p {
                            font-size: 3.6vw;
                        }
                    }

                    p {
                        font-size: 3.6vw;
                    }
                }
            }

            .home-about-us-content {
                font-size: 3.33vw;
                margin-bottom: 5.25vw;
            }

            .button {
                width: 43.95vw;
            }
        }
    }
}
