.footer{
    background: #111;
    padding-top: 3.6vw;
    position: relative;
    z-index: 5;
    overflow: hidden;

    &::after{
        content: "";
        background-color: rgba(181,150,77, 0.16);
        filter: blur(150px); 
        position: absolute;
        bottom: -33.75vw;
        width: 50vw;
        height: 47.5vw;
        border-radius: 50%;
        left: calc(50% - 25vw);
    }

    .footer-container{
        display: flex;
        flex-direction: column;
        gap: 1.73vw;

        .footer-content{
            display: flex;
            justify-content: space-around;
            position: relative;
            z-index: 3;

            .footer-logo svg{
                width: 17.7vw;
            }
            
            .footer-content-items-container{
                display: flex;


                .footer-content-item{
                    display: flex;
                    flex-direction: column;
                    gap: 1.38vw;
                    width: 8.6vw;

                    .footer-content-item-title{
                        text-decoration: none;
                        color: #fff;
                        font-weight: bold;
                        font-size: 1.25vw;
                        text-transform: capitalize;
                        display: flex;
                        flex-direction: column;
                        gap: 0.2vw;
                        width: fit-content;

                        &::after{
                            content: "";
                            height: 0.08vw;
                            max-width: 0;
                            background: #fff;
                            transition: all ease 0.4s;
                        }
            
                        &:hover{
                            &::after{
                                max-width: 100%;
                            }
                        }
                    }

                    .footer-content-item-links{
                        display: flex;
                        flex-direction: column;
                        gap: 0.5vw;

                        a{
                            font-size: 1vw;
                            color: #aaa;
                            text-transform: capitalize;
                            text-decoration: none;
                            line-height: 150%;
                            font-weight: medium;
                            display: flex;
                            flex-direction: column;
                            gap: 0.2vw;
                            width: fit-content;
        
                            &::after{
                                content: "";
                                height: 0.08vw;
                                max-width: 0;
                                background: #aaa;
                                transition: all ease 0.4s;
                            }
                
                            &:hover{
                                &::after{
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                }

            }
        }

        .footer-bottom-content{
            padding: 1.73vw;
            border-top: 1px solid rgba(255, 255, 255, 0.15);

            p{
                text-align: center;
                font-size: 1vw;
                color: #888;
            }
        }
    }
}

@media (max-width: 768px) {
    .footer {
        padding-top: 5.4vw;

        &::after {
            bottom: -50.625vw;
            width: 75vw;
            height: 71.25vw;
            left: calc(50% - 37.5vw);
        }

        .footer-container {
            gap: 2.595vw;

            .footer-content {
                flex-direction: column;
                align-items: center;
                gap: 5vw;

                .footer-logo svg {
                    width: 26.55vw;
                }

                .footer-content-items-container{
                    width: 100%;
                    justify-content: space-around;

                    .footer-content-item {
                        gap: 2.07vw;
                        width: 12.9vw;

                        .footer-content-item-title {
                            font-size: 1.875vw;
                            gap: 0.3vw;

                            &::after {
                                height: 0.12vw;
                            }
                        }

                        .footer-content-item-links {
                            gap: 0.75vw;

                            a {
                                font-size: 1.5vw;
                                gap: 0.3vw;

                                &::after {
                                    height: 0.12vw;
                                }
                            }
                        }
                    }
                }
            }

            .footer-bottom-content {
                padding: 2.595vw;

                p {
                    font-size: 1.5vw;
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .footer {
        padding-top: 10.8vw;

        &::after {
            bottom: -101.25vw;
            width: 150vw;
            height: 142.5vw;
            left: calc(50% - 75vw);
        }

        .footer-container {
            gap: 5.19vw;

            .footer-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10vw;

                .footer-logo svg {
                    width: 53.1vw;
                }

                .footer-content-items-container{

                    .footer-content-item {
                        gap: 4.14vw;
                        width: 25.8vw;

                        .footer-content-item-title {
                            font-size: 3.75vw;
                            gap: 0.6vw;

                            &::after {
                                height: 0.24vw;
                            }
                        }

                        .footer-content-item-links {
                            gap: 1.5vw;

                            a {
                                font-size: 3vw;
                                gap: 0.6vw;

                                &::after {
                                    height: 0.24vw;
                                }
                            }
                        }
                    }
                }
            }

            .footer-bottom-content {
                padding: 5.19vw;

                p {
                    font-size: 3vw;
                }
            }
        }
    }
}
