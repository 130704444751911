.our-team {
    display: flex;
    justify-content: space-around;
    z-index: 1;

    .our-team-item {
        display: flex;
        flex-direction: column;
        gap: 1.11vw;

        img {
            width: 20vw;
        }

        .our-team-text {
            display: flex;
            flex-direction: column;

            .title {
                font-size: 1.67vw;
            }
        }
    }
}

@media (max-width: 768px) {
    .our-team {
        .our-team-item {
            gap: 1.665vw;

            img {
                width: 30vw;
            }

            .our-team-text {
                .title {
                    font-size: 2.505vw;
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .our-team {
        flex-direction: column;
        gap: 10vw;

        .our-team-item {
            gap: 3.33vw;
            align-items: center;

            img {
                width: 60vw;
            }

            .our-team-text {
                .title {
                    font-size: 5.01vw;
                }
            }
        }
    }
}
