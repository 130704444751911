.home-hero {
    width: 100%;
    // background: url('../../../assets//home/hero.jpg');
    background-size: cover;
    height: 62.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .hero-3d-object {
        width: 33%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .hero-container {
        width: 55.56vw;
        gap: 3.6vw;
        display: flex;
        flex-direction: column;
        z-index: 2;

        .hero-title {
            font-size: 6.95vw;
            text-align: center;
            color: #fff;
            font-family: 'Ganora';
            line-height: 120%;
            font-weight: 400;
        }

        .hero-subtitle {
            color: #fff;
            font-size: 0.97vw;
            color: #ccc;
            text-align: center;
        }

        .button {
            width: 13.2vw;
            margin: 0 auto;
        }
    }
}

@media (max-width: 768px) {
    .home-hero {
        flex-direction: column;
        height: fit-content;

        .hero-3d-object {
            width: 100%;
            height: 70vw;
        }
        .hero-container {
            width: 83.34vw;
            gap: 5.4vw;

            .hero-title {
                font-size: 10.43vw;
            }

            .hero-subtitle {
                font-size: 1.46vw;
            }

            .button {
                width: 19.8vw;
            }
        }
    }
}

@media (max-width: 430px) {
    .home-hero {
        .hero-3d-object {
            height: 130vw;
        }

        .hero-container {
            gap: 10.8vw;

            .hero-title {
                font-size: 20.85vw;
            }

            .hero-subtitle {
                font-size: 2.91vw;
            }

            .button {
                width: 39.6vw;
            }
        }
    }
}
